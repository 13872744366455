(function () {
    const taxLayout = document.querySelector('.tax-layout');
    if (taxLayout) {
        const items = taxLayout.querySelectorAll('.tax-grid__item');
        const summary = taxLayout.querySelector('.tax-layout__summary');
        items.forEach(item => {
            const text = item.querySelector('.tax-grid__item-content').cloneNode(true);
            text.classList.remove('tax-grid__item-content')
            item.addEventListener('click', function (e) {
                if (window.innerWidth >= 768) {
                    e.preventDefault()
                    items.forEach(it => it.classList.remove('opened'));
                    item.classList.add('opened');
                    summary.replaceChildren();
                    summary.appendChild(text)
                }
            })
        })
    }
})()